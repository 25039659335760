import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router';

import { linkWithQuery, objectMerge, resolveParams, resolveState } from '@labradorsports/utils';

import { RootState, routerActions, RouterState } from '../../store/index.js';
import { baseParamState, paramSetup, usedQueryParams } from '../constants.js';
import { useDispatcher } from '../hooks/index.js';

export interface RouterContextValue {
    navigateWithQuery: (to: string | number, stateMods?: Partial<RouterState>, options?: { replace?: boolean }) => void;
}

export const RouterContext = createContext<RouterContextValue>(null);

export const RouterStateProvider: FC = ({ children }) => {
    const navigate = useNavigate();
    const updateState = useDispatcher(routerActions.UpdateRouterState);
    const state = useSelector((state: RootState) => state.router);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const prevResolvedParams = useRef(new URLSearchParams());
    const skipLocationUpdate = useRef(true);

    useEffect(() => {
        // Skip location update when state update was caused by previous location update
        if (skipLocationUpdate.current) {
            skipLocationUpdate.current = false;
            return;
        }

        const resolvedParams = resolveParams(paramSetup, location.pathname, state);

        const outsiderParams = Array.from(searchParams.entries()).filter(([p]) => !usedQueryParams.includes(p));
        outsiderParams.forEach(([key, value]) => {
            resolvedParams.set(key, value);
        });

        // If params have changed, trigger a location update
        if (resolvedParams.toString() !== prevResolvedParams.current?.toString()) {
            setSearchParams(resolvedParams);
        }

        prevResolvedParams.current = resolvedParams;
    }, [state]);

    useEffect(() => {
        const paramState = resolveState(paramSetup, location.pathname, searchParams);

        updateState(objectMerge(baseParamState, paramState));
    }, [location]);

    // TODO: do we need the individual router state setters anymore?
    const navigateWithQuery = useCallback(
        (to: string | number, stateMods?: Partial<RouterState>, { replace = false } = {}) => {
            // Handle back/forward navigation
            if (typeof to === 'number') {
                navigate(to);
                return;
            }

            if (location.pathname === to && !stateMods) {
                return;
            }

            skipLocationUpdate.current = true;

            if (stateMods) {
                updateState(stateMods);
            }

            const extraSearchParams = stateMods
                ? resolveParams(paramSetup, to, {
                      ...state,
                      ...stateMods,
                  })
                : new URLSearchParams('');

            const combinedSearchParams = new URLSearchParams({
                ...Object.fromEntries(searchParams),
                ...Object.fromEntries(extraSearchParams),
            });

            navigate(linkWithQuery(to, combinedSearchParams), { replace });
        },
        [searchParams, location.pathname]
    );

    return (
        <RouterContext.Provider
            value={{
                navigateWithQuery,
            }}
        >
            {children}
        </RouterContext.Provider>
    );
};

export const useNavigateWithQuery = () => {
    const { navigateWithQuery } = useContext(RouterContext);

    return navigateWithQuery;
};
